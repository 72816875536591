<!--

      This component allows to enter a postal code in order to get informed
      about which supermarkets are available. Also it saves the postal code
      in the user profile, submitting it to the api.

 -->

<template>
  <div class="section-title">Postleitzahl</div>
  <div class="white-box" v-if="this.$store.state.profile && $store.state.profile.id">
    <div class="secondary-text">Gib deine Postleitzahl ein und wähle dann den verfügbaren Supermarkt, bei dem du einkaufen möchtest.</div>
    <div class="postal-code-field">
      <ion-input class="postal-code-input" placeholder="10179" pattern="[0-9]{5}" inputmode="number" :value="this.postalCode" type="number" onfocus="this.value = this.value;" data-cy="postal-code" @ionChange="enterZipCode($event)"></ion-input>
    </div>
    <!-- Loading the supermarkets -->
    <div v-if="postalCode && !$store.state.profile.preferred_store_branch" class="loading-supermarket">
      <ion-spinner name="crescent" class="centered"></ion-spinner>
    </div>

    <!-- Case 1: No supermarket is available -->
    <div v-if="$store.state.storeBranches && $store.state.storeBranches.length === 0">
      <div class="secondary-text">Leider liefert keiner unserer Partner-Supermärkte in deiner Region. Wir arbeiten hart daran, weitere Supermärkte anzubinden.</div>
    </div>

    <!-- Case 2: A supermarket has already been selected and - if possible - the user may select a different one -->
    <div v-if="$store.state.profile.preferred_store_branch && $store.state.storeBranches">
      <div class="secondary-text" v-if="$store.state.storeBranches.length === 1">Wir beliefern dich mit</div>
      <div class="secondary-text" v-if="$store.state.storeBranches.length > 1">Wähle deinen Supermarkt:</div>
      <div class="supermarket" @click="clickedSupermarket(storebranch)" v-for="storebranch in $store.state.storeBranches" :key="storebranch.id">
        <Icon name="checkmarkOutline" class="icon-active-green" v-if="$store.state.profile.preferred_store_branch.id === storebranch.id" />
        <img class="supermarket-logo" :src="constructImageUrl(storebranch.store_logo)" alt="">
      </div>
      <div class="secondary-text-gray" v-if="$store.state.profile.preferred_store_branch.store_name === 'Rewe'">
        Mindestbestellwert REWE Lieferservice: 50€<br>
        Mindestbestellwert REWE Abholservice: 0€
      </div>
    </div>

    <div class="secondary-text text-red" v-if="validated === false">Bitte gib deine Postleitzahl ein!</div>
  </div>
  <Address v-if="$store.getters.useInternalPayment" />
  <OrderDay />
</template>

<script>
import OrderDay from '@/components/settings/OrderDay.vue'
import Address from '@/components/settings/Address.vue'

import { Plugins } from '@capacitor/core'
const { Keyboard } = Plugins

import {
  IonInput,
  IonSpinner
} from '@ionic/vue'

export default {
  name: 'supermarket',
  components: {
    IonInput,
    IonSpinner,
    OrderDay,
    Address
  },
  data () {
    return {
      validated: null,
      postalCode: null,
      enteredPostalCode: null,
    }
  },
  methods: {
    constructImageUrl(logo) {
      return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER5 + logo
    },
    validate () {
      if (!this.enteredPostalCode || this.enteredPostalCode.length < 5) {
        setTimeout(() => {
          if (!this.enteredPostalCode || this.enteredPostalCode.length < 5) {
            this.validated = false
          }
        }, 3000)
      } else {
        this.validated = true
      }
    },
    enterZipCode (event) {
      this.validate()
      this.$store.dispatch('applySettings', { key: 'preferred_store_branch', value: null })
      this.enteredPostalCode = event.detail.value
      if (event && event.detail.value && event.detail.value.length === 5) {
        if (process.env.NODE_ENV !== 'development') {
          Keyboard.hide()
        }
        this.$store.dispatch('applySettings', { key: 'postal_code', value: event.detail.value })
      } else if (event && event.detail.value && event.detail.value.length === 0) {
        this.$store.dispatch('applySettings', { key: 'postal_code', value: null })
      }
    },
    async clickedSupermarket (storebranch) {
      // Try to find the old storebranch, if there is one
      let oldStoreBranch
      if (this.$store.state.profile && this.$store.state.profile.preferred_store_branch) {
        oldStoreBranch = this.$store.state.profile.preferred_store_branch
      }

      // Update the storebranch
      await this.$store.dispatch('applySettings', { key: 'preferred_store_branch', value: storebranch.id })

      // If the user switched the storebranch from an old one to a new one, remove the old order.
      // This function then automatically sets a new order and new plan.
      if (oldStoreBranch && oldStoreBranch.id !== storebranch.id) {
        this.$store.dispatch('refreshOrderAfterSupermarketSwitch')
      }
    }
  },
  created () {
    this.$store.dispatch('applySettings', { key: 'preferred_store_branch', value: null })
    if (this.$store.state.profile && this.$store.state.profile.postal_code) {
      this.postalCode = this.$store.state.profile.postal_code
      this.enteredPostalCode = this.$store.state.profile.postal_code
      this.$store.dispatch('applySettings', { key: 'postal_code', value: this.$store.state.profile.postal_code })
    }
  },
  updated () {
    this.validate()
  },
  beforeUnmount () {
    this.$store.dispatch('validatePostalCodeAndStorebranch')
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.postal-code-field {
  border: solid 1px $col-light-gray-font;
  border-radius: $border-radius;
  width: 200px;
  margin: 16px 0 8px 0;
}

.postal-code-input {
  display: inline-block;
  font-size: 1.16rem;
  background: white;
  border-radius: $border-radius;
  line-height: 1;
  letter-spacing: 1.58rem;
  text-align: left;
  border-left: solid white 40px !important;
}

.supermarket {
  display: inline-block;
  position: relative;
  width: 33%;
  margin-top: 8px;
  padding: 10px;
  .icon-active-green {
    right: 0;
    top: 0;
    height: 20px;
    width: 20px;
    position: absolute;
  }
}

.supermarket-logo {
  height: auto;
  border-radius: 4px;
}

.loading-supermarket {
  z-index: 2;
  background: white;
  opacity: .7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}
</style>
