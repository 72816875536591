<!--

      This component allows to edit the number of adults and children in the
      household. It is presented in the onboarding survey and the household
      settings view.

 -->

<template>
    <div class="section-title headline">Personen</div>
    <div class="white-box">
      <div class="main-text">Wieviele Personen essen mit?</div>
      <br><br>
      <div class="main-text text-center">Erwachsene</div>
      <div class="people" v-if="this.$store.state.profile" :key="this.$store.state.profile.adults">
        <div v-for="index in 5" :key="index" class="btn-item" @click="this.setAdults(index)">
          <Icon name="foodableCookHead" class="adult" v-show="$store.state.profile.adults >= index" />
          <Icon name="foodableCookHeadOutline" class="adult" v-show="!($store.state.profile.adults >= index)" />
        </div>
      </div>
      <br>
      <div class="main-text text-center">Kinder</div>
      <div class="secondary-text-gray text-center">wir berechnen eine halbe Portion</div>
      <div class="people" v-if="this.$store.state.profile" :key="this.$store.state.profile.children">
        <div v-for="index in 5" :key="index" class="btn-item" @click="this.setChildren(index)">
          <Icon name="foodableCookHead" class="child" v-show="$store.state.profile.children >= index" />
          <Icon name="foodableCookHeadOutline" class="child" v-show="!($store.state.profile.children >= index)" />
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'household',
  methods: {
    async updateMealPlanServingSize () {
      if (this.$store.state.newplan) {
        const singleDays = this.$store.state.newplan.single_days
        const planRecipeIds = singleDays.map(day => day.planrecipes[0].id)
        const servingSize = this.$store.state.profile.adults + (this.$store.state.profile.children * 0.5)
        if (servingSize === 0) {
          return
        }
        planRecipeIds.forEach(planRecipeId => {
          const details = {
            url: this.$store.state.api.plan.rp,
            id: planRecipeId,
            method: 'PATCH',
            data: { serving_size: servingSize }
          }
          this.$store.dispatch('apiSend', details).then(result => {
            if (result) {
              const planRecipe = this.$store.state.newplan.single_days.map(sd => sd.planrecipes).flat().find(pr => pr.id === planRecipeId)
              planRecipe.serving_size = result.serving_size
              this.$store.dispatch('refreshExistingOrder')
            }
          })
        })
      }
    },
    setAdults (val) {
      if (this.$store.state.profile) {
        const oldVal = this.$store.state.profile.adults
        if (val === oldVal) {
          this.$store.state.profile.adults -= 1
        } else {
          this.$store.state.profile.adults = val
        }
        this.$store.dispatch('applySettings', { key: 'adults', value: this.$store.state.profile.adults })
        this.updateMealPlanServingSize()
      }
    },
    setChildren (val) {
      if (this.$store.state.profile) {
        const oldVal = this.$store.state.profile.children
        if (val === oldVal) {
          this.$store.state.profile.children -= 1
        } else {
          this.$store.state.profile.children = val
        }
        this.$store.dispatch('applySettings', { key: 'children', value: this.$store.state.profile.children })
        this.updateMealPlanServingSize()
      }
    },
  },
  beforeUnmount () {
    this.$store.dispatch('checkHouseholdSize')
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.adult {
  width: 36px;
}

.child {
  width: 26px;
  margin: 0 5px;
}

.people {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
</style>
