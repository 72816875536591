<!--

      This component allows to decice which product types to buy
      in organic quality, if possible.

 -->

<template>
  <div>
    <div v-for="group in $store.getters.ingredientcategorygroups" :key="group">
      <div class="section-title">{{ groupNames[group] }}</div>
      <div class="white-box">
        <div class="exclude-item-group">
          <div v-for="item in $store.state.ingredientcategories.filter(i => i.exclusion_group === group)" v-bind:key="item.id" class="an-exclude-item btn-item" @click="$store.dispatch('toggleExcludeItem', item)">
            <Icon :name="iconName(item.name)" class="icon-foodable" />
            <Icon v-if="$store.state.profile && $store.state.profile.ingredient_category_exclusions" name="closeOutline" class="icon-active-orange pos-top-right-inset" v-show="$store.state.profile.ingredient_category_exclusions.some(i => i.id === item.id)" />
            <div class="secondary-text text-center">{{ item.name_german }}</div>
            <Icon name="helpCircleOutline" class="icon-help" v-if="showHelp(item.name_german)" @click.stop="clickedHelp(item.name_german)" />
          </div>
        </div>
        <div class="btn-secondary standalone" @click="$store.dispatch('toggleExcludeGroup', group)">Alle ausschließen</div>
      </div>
    </div>
    <div class="section-title">Weiteres</div>
    <div class="white-box search-box" v-if="$store.state.profile">
      <ion-searchbar
        placeholder="z.B. Rosenkohl"
        class="search"
        ref="searchbar"
        :value="searchValue"
        @ionInput="searchExclusions($event)"
        @ionChange="searchExclusions($event)"
        @ionClear="resetSearch()"
      ></ion-searchbar>
      <div 
        class="search-results" 
        :key="searchResults" 
        v-if="searchResults && searchResults.length && searchTerm" 
      >
        <div 
          v-for="result in searchResults" 
          :key="result.id"
          @click.stop="selectResult(result)"
          class="search-result"
        > 
          {{ result.name }} 
        </div>
      </div>
      <div 
        v-for="exclusion in $store.state.profile.shopping_ingredient_exclusions" 
        :key="exclusion.id" 
        class="exclusion-tag"
        @click="removeExcludedShoppingIngredient(exclusion)"
      >
        {{ exclusion.name }}
        <Icon name="closeOutline" class="icon-20" />
      </div>
    </div>
    <div class="secondary-text-gray text-section">Wir garantieren und haften nicht dafür, dass ausgeschlossene Zutaten wirklich nicht in den Supermarkt-Produkten enthalten sind. Kontrolliere dies bitte selbst!</div>
  </div>
</template>

<script>
import {
  IonSearchbar
} from '@ionic/vue'

export default {
  name: 'exclude',
  components: {
    IonSearchbar
  },
  data () {
    return {
      groupNames: {
        p: "Pflanzliches",
        a: "Tierprodukte",
        mf: "Fisch & Fleisch",
        al: "Allergene",
      },
      help: [
        {
          name: 'Sonstige Tierprodukte',
          modal: 'OtherAnimalProduce'
        },
        {
          name: 'Milchprodukte',
          modal: 'OtherDairyProducts'
        }
      ],
      searchResults: null,
      searchTerm: null,
      searchValue: null
    }
  },
  methods: {
    iconName (name) {
      if (name.length) {
        return 'foodable' + name[0].toUpperCase() + name.slice(1).replace(/\s/g, '')
      }
    },
    searchExclusions (event) {
      if (event && event.detail && typeof event.detail.value === 'string') {
        this.searchTerm = event.detail.value
        const details = {
          url: this.$store.state.api.shop.ingredientSearch + '?query=' + this.searchTerm
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result && result.length) {
            this.searchResults = result
          }
        })
      }
    },
    resetSearch () {
      this.searchTerm = null
      this.$refs.searchbar.$el.value = null
    },
    selectResult (result) {
      this.resetSearch()
      const newExclusions = this.$store.state.profile.shopping_ingredient_exclusions.map(ex => ex.id)
      newExclusions.push(result.id)
      this.$store.dispatch('applySettings', { key: 'shopping_ingredient_exclusions', value: newExclusions })
    },
    removeExcludedShoppingIngredient (exclusion) {
      const updatedExclusions = this.$store.state.profile.shopping_ingredient_exclusions.filter(s => s.id !== exclusion.id).map(ex => ex.id)
      this.$store.dispatch('applySettings', { key: 'shopping_ingredient_exclusions', value: updatedExclusions })
    },
    clickedHelp (name) {
      const help = this.help.find(h => h.name === name)
      if (help) {
        this.$store.dispatch('openModal', { name: help.modal })
      }
    },
    showHelp (name) {
      return this.help.find(h => h.name === name)
    }
  },
  mounted () {
    if (!this.$store.state.ingredientcategories) {
      this.$store.dispatch('getIngredientCategories')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.exclusion-tag {
  @extend .btn-toggle;
  @extend .btn-toggle-selected;
  @extend .main-text;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  padding-right: 6px;
}

.icon-help {
  display: block;
  margin: 0 auto;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  --stroke: $col-font;
  stroke: $col-font;
  fill: $col-font;
}

.search-box {
  position: relative;
}

.search-results {
  @extend .main-text;
  background: white;
  box-shadow: $box-shadow;
  padding: 10px 20px;
  margin: 0 20px;
  position: absolute;
  width: calc(100% - 80px);
  border-radius: 4px;
  z-index: 1;
}

.search-result {
  padding: 5px 0;
}
</style>