<!--

      This component allows to enter a postal code in order to get informed
      about which supermarkets are available. Also it saves the postal code
      in the user profile, submitting it to the api.

 -->

<template>
  <InfoBox icon="foodableLightBulb" :right="true">
    Wähle hier Rezeptkategorien, die dich interessieren. So findest du besser Rezepte, die dir gefallen.
  </InfoBox>
  <div class="text-section">
    <div class="main-text">Zeigt mir Rezepte...</div>
  </div>
  <div class="white-box" v-if="$store.state.recipeCategories && $store.state.recipeCategories.length > 0">
    <div 
      v-for="cat in $store.state.recipeCategories.filter(c => c.selectable).sort((a,b) => a.default_position - b.default_position)" 
      :key="cat.title" 
      :class="['btn-toggle', { 'btn-toggle-selected': isSelected(cat) }]"
      @click="clickRecipeCategory(cat)"
    >
      <div class="main-text">{{ cat.short_title }}</div>
    </div>
  </div>
</template>

<script>
import InfoBox from '@/components/info/InfoBox.vue'

export default {
  name: 'recipecategories',
  emits: ["edited"],
  components: {
    InfoBox
  },
  data () {
    return {
      selected: null
    }
  },
  methods: {
    isSelected(category) {
      if (this.$store.state.selectedRecipeCategories && this.$store.state.selectedRecipeCategories.length > 0) {
        return this.$store.state.selectedRecipeCategories.some(c => c.category === category.id)
      }
    },
    clickRecipeCategory(category) {
      const details = {
        url: this.$store.state.api.selectedRecipeCategories
      }

      if (this.isSelected(category)) {
        details.id = this.getSelectionId(category)
        details.method = 'DELETE'
      } else {
        details.method = 'POST'
        details.data = { category: category.id }
      }
      this.$store.dispatch('apiSend', details).then(async result => {
        let updateSelection = this.$store.state.selectedRecipeCategories
        if (result && result.hasOwnProperty('category')) {
          updateSelection.push(result)
        } else if (result) {
          updateSelection = updateSelection.filter(c => c.category !== category.id)
        }
        console.log(updateSelection)
        this.selected = updateSelection.filter(c => c.selectable)
        await this.$store.dispatch('save', { key: 'selectedRecipeCategories', value: updateSelection })
        this.$store.dispatch('getSelectedRecipeCategories')
        this.$emit('edited')
      })
    },
    getSelectionId (category) {
      // We need the id of the selected recipe category as mentioned in the profile.
      // But at first we only have the global id of the category.
      if (this.$store.state.selectedRecipeCategories) {
        const found = this.$store.state.selectedRecipeCategories.find(cat => cat.category === category.id)
        if (found) return found.id
      }
    },
  },
  mounted () {
    if (!this.$store.state.recipeCategories) {
      this.$store.dispatch('getRecipeCategories')
    }
  }
}
</script>
