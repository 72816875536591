<!--

      This component allows to select a week day (Mo - Su) and a time
      when the app is supposed to remind you to order your next menu.

 -->

<template>
  <div class="section-title">Standard-Bestelltag</div>
  <div class="white-box">
    <div class="secondary-text">Wähle einen Standard-Bestelltag.</div>
    <div class="tertiary-text-gray">Natürlich kannst du auch jederzeit flexibel bestellen.</div>
    <div v-for="day in weekDays" :key="day" :class="['btn-toggle-round', { 'btn-toggle-selected': day === selectedDay }]" @click="selectDay(day)">
      <div class="main-text centered">{{ day }}</div>
    </div>
  </div>

  <div class="section-title" v-if="selectedDay">Bestellerinnerung</div>
  <div class="white-box" v-if="selectedDay">
    <div class="secondary-text">Zu welcher Uhrzeit möchtest du an deine Bestellung erinnert werden?</div><br>
    <div class="main-text">{{ fullSelectedDay }}</div>
    <ion-datetime 
      class="time-selector" 
      display-format="HH:mm" 
      :key="this.hourAndMinute"
      :value="this.hourAndMinute"
      @ionBlur="this.selectTime($event)"
      @ionChange="this.selectTime($event)"
      ></ion-datetime>
    <ion-toggle style="margin-bottom: 6px;" :checked="$store.getters.orderDayNotificationOn" @click="$store.dispatch('toggleOrderDayNotification')"></ion-toggle>
    <div class="secondary-text-gray text-center" v-if="$store.getters.orderDayNotificationOn">Erinnerung aktiv</div>
  </div>

  <InfoBox icon="foodableBell">
    Keine Angst vor einem leeren Kühlschrank und knurrendem Magen! Wir erinnern dich rechtzeitig, 
    damit du nicht vergisst wieder zu bestellen.
  </InfoBox>

</template>

<script>
import InfoBox from '@/components/info/InfoBox.vue'

import {
  IonDatetime,
  IonToggle
} from '@ionic/vue'

export default {
  name: 'orderday',
  components: {
    IonToggle,
    IonDatetime,
    InfoBox
  },
  data () {
    return {
      hourAndMinute: '2011-04-11T11:00:00',
      weekDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      weekDaysFull: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    }
  },
  computed: {
    selectedDay () {
      if (this.$store.state.profile) {
        return this.weekDays[this.$store.state.profile.order_day]
      }
    },
    fullSelectedDay () {
      if (this.$store.state.profile) {
        return this.weekDaysFull[this.$store.state.profile.order_day]
      }
    }
  },
  methods: {
    selectDay (day) {
      if (this.weekDays.indexOf(day) === this.$store.state.profile.order_day) {
        this.$store.state.profile.order_day = null
      } else {
        this.$store.state.profile.order_day = this.weekDays.indexOf(day)
      }

      // Save the new day string
      const enabled = typeof this.$store.state.profile.order_day === 'number'
      const updates = [
        { key: 'order_day', value: this.$store.state.profile.order_day },
        { key: 'order_notification_enabled', value: enabled }
      ]
      if (!this.$store.state.profile.order_time) {
        // Also save the currently selected time string
        this.setTimeFromProfile()
        const time = this.getTimeString(this.hourAndMinute)
        if (this.$store.state.profile && time !== this.$store.state.profile.order_time) {
          updates.push({ key: 'order_time', value: time })
        }
      }
      this.$store.dispatch('applySettings', updates)
    },
    selectTime (event) {
      if (event && event.detail && event.detail.value) {
        const time = this.getTimeString(event.detail.value)
        if (this.$store.state.profile && time && time !== this.$store.state.profile.order_time) {
          this.$store.dispatch('applySettings', { key: 'order_time', value: time }).then(() => {
            this.setTimeFromProfile()
            if (!this.$store.getters.orderDay) {
              // Also set the day if it is not yet set
              this.$store.dispatch('applySettings', { key: 'order_day', value: 0 })
            }
          })
        } else {
          if (!this.$store.getters.orderDay) {
            // Also set the day if it is not yet set
            this.$store.dispatch('applySettings', { key: 'order_day', value: 0 })
          }
        }
      }
    },
    getTimeString (time) {
      const newTime = new Date(time)
      return newTime.getHours().toString() + ':' + newTime.getMinutes().toString() + ':00'
    },
    setTimeFromProfile () {
      if (this.$store.state.profile && this.$store.state.profile.order_time) {
        this.hourAndMinute = '2011-04-11T' + this.$store.state.profile.order_time
      }
    }
  },
  created () {
    this.setTimeFromProfile()
  },
  mounted () {
    this.setTimeFromProfile()
  }
}
</script>
