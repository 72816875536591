<!--

      This component allows to enter a postal code in order to get informed
      about which supermarkets are available. Also it saves the postal code
      in the user profile, submitting it to the api.

 -->

<template>
  <div class="text-section">
    <div class="main-text">Wobei magst du lieber Bio?</div>
    <div class="secondary-text-gray">Wenn möglich, werden Produkte nach deinen Wünschen ausgewählt.</div>
  </div>
  <div class="section-title">Bio-Qualität</div>
  <div class="white-box">
    <div class="exclude-item-group" v-if="this.$store.state.biocategories">
      <div v-for="item in this.$store.state.biocategories" v-bind:key="item.id" class="an-exclude-item btn-item" @click="$store.dispatch('toggleBioItem', item)">
        <Icon :name="iconName(item.name)" class="icon-foodable" />
        <Icon v-if="$store.state.profile && $store.state.profile.ingredient_categories_bio" name="checkmarkOutline" class="icon-active-green pos-top-right-inset" v-show="$store.state.profile.ingredient_categories_bio.some(i => i === item.id)" />
        <div class="secondary-text text-center">{{ item.name_german }}</div>
      </div>
    </div>
  </div>
  <InfoBox icon="foodableLightBulb">Kaufst du oft ein Produkt einer bestimmten Marke oder mit einer bestimmten Qualifizierung, merkt sich unsere Künstliche Intelligenz das und wählt in Zukunft automatisch diese Produkte für dich.</InfoBox>
</template>

<script>
import InfoBox from '@/components/info/InfoBox.vue';

export default {
  name: 'bio',
  components: {
    InfoBox
  },
  methods: {
    iconName (name) {
      if (name.length) {
        return 'foodable' + name[0].toUpperCase() + name.slice(1).replace(/\s/g, '')
      }
    },
  }
}
</script>
